(function ($) {
    var _ = {},
        self = {};

    self.$obj = $('.gl-calc-height');

    self.init = function () {
        if (!self.$obj.length) return;

        var height = window.innerHeight,
            newHeight = height - $('.header').outerHeight();

        self.$obj.css('min-height', newHeight);
    };

    self.init();

    return self;
})(jQuery);
